<template>
  <head>
    <title>ぐっすりーぷ</title>
  </head>
  <aheader/>
  <!-- <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0"> -->
  <div class="container is-fluid">
    <!-- <div class="section">
      <p class="title">
        ぐっすりーぷとは？
      </p>
    </div> -->
    <div class="block">
      <!-- <img height="400" width="600" style="float: bottom;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/goodsleep%2Fgoodsleep_logo.jpg?alt=media&token=b38d6c40-9b77-4130-b02c-1306b29e5698"> -->
      <img height="400" width="600" style="float: bottom;" src="@/assets/goodsleep_logo.jpg">
      <div class="content is-normal">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <h2><u>ぐっすりーぷとは？</u></h2>
            <h4 style="line-height : 220%; text-align: left ;">100年の歴史を持つふとん専門店、おぐら屋がおすすめする最高の寝具です。スッキリした目覚め、活力ある1日のスタートを迎えたい方にご利用いただきたいサービスです。
              世界の中でも極端に睡眠時間が少ない国であり、真面目で一生懸命な国民性も相まって日本で暮らす多くの方は満足な睡眠が取れていないと感じています。そこで、おぐら屋が持つ知識と経験をお伝えすることで、1人でも多くの方々の睡眠の質を高めていただき、楽しく健やかな暮らしを送っていただきたいと考えております</h4>
          </div>
        </div>
        <!-- <img height="400" width="600" class="is-rounded" style="border-radius: 150px;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/order_made%2FOrderMade0_top2.png?alt=media&token=bfd707e8-7233-4f06-b9f5-428fc2b260a2"> -->
        <img height="400" width="600" class="is-rounded" style="border-radius: 150px;" src="@/assets/OrderMade0_top2.png">
      </div>
    </div>
    <div class="block">
      <div class="content is-normal">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <h4 style="line-height : 220%; text-align: left;">掛ふとん・敷ふとん・枕・メンテナンスを中心に理想の睡眠環境をつくるお手伝いを致します。羽毛をシルク真綿で挟むという特殊三層構造の掛ふとんは、羽毛とシルクの特性をいかし、
              抜群の吸湿・放湿性を保ちつつ、シルクが生み出す他にはない柔らかい肌添いが生まれます。身体を支える敷ふとんは、お客様の身体に合わせてバランスを調整。体圧測定器の結果をもとに熟練のスタッフがつくるお客様専用のふとんです。
              他に、まくらやパジャマなどの厳選した商品や睡眠環境の相談などのお手伝いで質の高い睡眠を提供し、日々の暮らしを充実させたいお客様をサポートしております。</h4>
          </div>
        </div>
        <!-- <img height="400" width="600" class="is-rounded" style="border-radius: 150px;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/maintenance%2Fjosei_mezame.jpg?alt=media&token=184a67c4-7f65-443d-882c-eabfd509d521"> -->
        <img height="400" width="600" class="is-rounded" style="border-radius: 150px;" src="@/assets/josei_mezame.jpg">
      </div>
    </div>
  </div>
  <afooter/>
</template>

<style>
#measure{
  line-height: 2;
  text-align:left
}
#tejun{
  background-color: has-background-grey-lighter;
}
.kyoutyou{
  display:inline-block;
  padding:5px 0;
  border-top:5px double black;
  border-bottom:5px double black;
}
.o_picture {
  position: relative;
  width: 500px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.o_picture::after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 10px 10px 10px 15px hsl(171, 100%, 96%);
}

.o_picture img {
  width: 150%;
}
</style>
<script>
  export default {
    data() {
      return {
      }
    },
    mounted() {
      this.scrollTop();
    },
    methods: {
      pushPage(num) {
        this.$router.push(num);
      },
      scrollTop() {
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        })
      },
    }
  }
</script>
